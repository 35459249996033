<template>
  <div class="tabled">
    <div class="tabled-cell Nunito">
      <h1 class="welcomeText" :class="{'has-text-danger': error, 'mainTextColor': !error}">{{ error ? 'Oh no!' : 'Success!' }}</h1>
      <h1 class="is-size-3" v-if="error">We could't add your server!!</h1>
      <h1 class="is-size-3" v-else>{{ guild ? guild.name : 'Your server' }} has been added!</h1>
      <h5 class="is-size-5 has-text-danger" v-if="error" style="padding-bottom: 10px;">Error: {{ error_desc }}</h5>
      <h5 class="is-size-5" v-else style="padding-bottom: 10px;">If you need any help, join our support server!</h5>
      <b-button v-if="!error" type="is-info" icon-pack="fas" icon-left="arrow-right" tag="router-link" :to="{ path: `/dashboard/${(guild ? guild.id : ($route.query.guild_id ? $route.query.guild_id : null))}/` }"><strong>Continue to dashboard</strong></b-button>
      <b-button v-else-if="error || !$route.query.guild_id" type="is-warning" icon-pack="fab" icon-left="discord" tag="router-link" :to="{ path: `/invite/?guild_id=${(guild ? guild.id : ($route.query.guild_id ? $route.query.guild_id : null))}/` }"><strong>Try again!</strong></b-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ServerAdded',
    data () {
      return {
        guild: null,
        error: false,
        error_desc: 'Unknown Error'
      }
    },
    created () {
      if (this.$route.query.error || this.$route.query.error_description || !this.$route.query.guild_id) {
        this.error = true
        if (this.$route.query.error_description) this.error_desc = decodeURIComponent(this.$route.query.error_description)
      } else {
        this.$store.dispatch('getApi', { path: `system/checkGuild/${this.$route.query.guild_id}?code=${this.$route.query.code}`, params: {} }).then(async (result) => {
          this.guild = result.data.guild
        }).catch(() => {
          this.error = true
          this.error_desc = 'Can\'t find guild'
        })
      }
    },
    methods: {}
  }
</script>

<style scoped>
.tabled {
  display: table;
  height: 35rem;
  margin: 0 auto;
}
.tabled-cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.welcomeText {
  font-size: 3.5em;
  filter: blur(0) brightness(100%) contrast(100%) grayscale(0) hue-rotate(0) saturate(100%);
  font-weight: 700;
  line-height: 1;
}
</style>
